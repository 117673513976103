<template>
  <div class="content">
    <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700" :text="showText" :is-full-screen="true" />

    <TheNav :navLinkList="navLinkList" :isJump="true" :classifyList="classifyList" />

    <div class="news-content" id="news">
      <div class="news-item" v-for="(item,key) in newsList" :key="key">
        <router-link :to="{name:'NewsDetail',params:{newsId:item.id}}">
          <div class="news-img-item"><img :src="item.newsImgUrl" alt="封面" class="news-img" /></div>
          <div class="news-title">{{ item.newsTitle }}</div>
          <div class="news-time">{{ item.createTime.substring(0,10) }}</div>
          <div class="news-desc">{{ removeHtmlStyle(item.newsContent) }}</div>
        </router-link>
      </div>
      <div class="pagination-content">
        <Pagination :currentPage="currentPage" :total="total" :pageSize="params.limit" :onPageChange="changePage" />
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsNavList } from "@/utils/common";
import { getNewsList } from "@/api/news";
import { removeHtmlStyle } from "@/utils";

export default {
  name: 'News',
  computed: {
    // 当前页，从查询参数 page 返回
    currentPage() {
      return parseInt(this.$route.query.page) || 1
    }
  },
  data() {
    return {
      params:{
        page:1,
        limit:6
      },
      classifyList:[],
      navLinkList:[],
      newsList:[],
      total: 0, // 文章总数
      classifyId:0,
      show:false,
      showText:''
    }
  },
   watch: {
	// 监听路由是否变化
	'$route' (to) { 
      if(to.query.page != this.params.page || to.params.newsId != this.classifyId){
        this.params.page = parseInt(to.query.page) || 1;// 把最新id赋值给定义在data中的id
        if(!this.$route.params.newsId) {
          let arr = this.$route.path.split('/')
          this.classifyId = arr[3] || 0
        }else {
          this.classifyId = parseInt( this.$route.params.newsId) || 0
        }
        this.init(); // 重新调用加载数据方法
      }
    },
  },
  created() {
    this.params.page = parseInt( this.$route.query.page) || 1
    let arr = this.$route.path.split('/')
    this.classifyId = arr[3] || 0
    this.init()
  },
  methods: {
    removeHtmlStyle,
     // 回调，组件的当前页改变时调用
    changePage(page) {
      // 在查询参数中混入 page，并跳转到该地址
      // 混入部分等价于 Object.assign({}, this.$route.query, { page: page })
      this.$router.push({ query: { ...this.$route.query, page, } })
    },
    init() {
      this.getNewsList()
    },
    getNewsList() {
      this.show = true;
      this.showText = "加载中...";
      this.classifyList = []
      this.navLinkList = getNewsNavList()
      console.log(this.params)
      getNewsList(this.params)
        .then(res => {
          if (res.code !== 1) {
            console.log(res.msg);
          }  else {
            if(res.data.length > 0) {
              let info 
              res.data.forEach((item) =>  {
                let id = item.id
                info = {
                  id:item.id,
                  name: item.newsTypeName,
                  newsList:item.newList,
                  count:item.count,
                  link:{name:'NewsListParams',params:{newsId:id}},
                  isActive:item.id == this.classifyId ? true:false,
                }
                this.classifyList.push(info)
              })
              let cate = []
               cate = this.classifyList.find(item => {return item.id == this.classifyId})
               if(!cate || cate.length == 0) {
                let id = this.classifyId
                if(id) {
                  this.classifyList[0].link = {name:'NewsListParams',params:{newsId:id}}
                }else {
                  this.classifyList[0].link = {name:'NewsList'}
                }
                cate = this.classifyList[0]
               }
               console.log(cate)
              this.newsList = cate.newsList
              this.total =cate.count
              // this.cateTitle = this.classifyList[0].name
              this.navLinkList.push({name:cate.name,link:''})
            }
          }
          this.show = false;
        })
        .catch(err => {
          console.log(err);
          this.show = false;
        });
    }
  }
}
</script>

<style scoped>
.news-content {
  padding:40px 10% 40px 10%;
  background-color: #f9f9f9;
  /* text-align: center; */
}

.nav-collpose {
  background-color: #fff;
}

.news-item {
  background-color: #fff;
  padding:20px 10px;
  width: 31.3%;
  display: inline-block;
  margin:10px 0.5%;
  text-align: left;
  cursor: pointer;
}

.news-item:hover {
  box-shadow: 2px 2px 10px #ccc;
}

.news-img-item {
  position: relative;
  width: 100%;
  display: inline-block;
  padding-top: 66.6%;
  height: 0;
}

.news-item .news-img {
  width:100%;
  height:100%;
  position: absolute;
  bottom:0;
  left:0;
}

.news-item .news-title {
  overflow: hidden; 
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.news-item .news-time {
  margin-bottom:10px;
  color:#999999;
}

.news-item .news-desc {
  overflow: hidden; 
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 40px;
  /* line-height: 16px; */
}


.content /deep/ .link-row-li a {
  background-color: #fff !important;
}


@media (max-width: 1360px) {
.news-item {
  width: 48%;
  margin:10px 1%;
}


}

@media (max-width: 768px) {
.news-item {
  width: 100%;
  margin:10px 0;
}

.news-item .news-desc {
  height: auto;
  /* line-height: 16px; */
}

}
</style>